import { Avatar, Box, List, ListItem, Skeleton } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useOrder } from "../../api/useOrder";

const placeholder = [{ placeholder: true, productId: -1 }];

interface OrderDetailsProps {
  orderId?: string;
}

const OrderDetails = ({ orderId }: OrderDetailsProps) => {
  const { orderSummary, isFetched } = useOrder({
    orderId
  });

  return (
    <Box>
      <Box
        sx={{
          textAlign: "left",
          typography: "body1",
          fontWeight: "bold",
          marginTop: 3,
          marginBottom: 2,
        }}
      >
        訂單詳情
      </Box>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          borderRadius: 3,
          boxShadow: "0px 0px 10px 1px #E9E9E9",
        }}
      >
        {isFetched && !orderSummary?.products?.length ? (
          <Box fontSize={14} paddingY={2}>
            未有訂單詳情
          </Box>
        ) : (
          (orderSummary?.products ?? placeholder).map((item, i) => (
            <ListItem key={item.productId} disableGutters>
              <Box
                sx={{
                  width: "100%",
                  paddingX: 3,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box
                    sx={{ marginRight: 1, fontSize: 14, fontWeight: "bold" }}
                  >
                    {"placeholder" in item ? "" : `${i + 1}.`}
                  </Box>
                  <Box>
                    {"placeholder" in item ? (
                      <>
                        <Skeleton variant="text" width={"30vw"} />
                        <Skeleton variant="text" width={"10vw"} />
                      </>
                    ) : (
                      <>
                        <Box sx={{ fontSize: 14, fontWeight: "bold" }}>
                          {item?.products?.name}
                        </Box>
                        <Box sx={{ fontSize: 12 }}>數量: {item.quantity}</Box>
                      </>
                    )}
                  </Box>
                </Box>
                <Box>
                  {"placeholder" in item ? (
                    <Skeleton variant="rectangular" width={40} height={40} />
                  ) : (
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href={item.products?.images?.[0]}
                    >
                      <Avatar
                        sx={{ bgcolor: "grey" }}
                        variant="rounded"
                        alt={item.products?.name}
                        src={item.products?.images?.[0]}
                      >
                        {item.products?.name?.[0] ?? "-"}
                      </Avatar>
                    </a>
                  )}
                </Box>
              </Box>
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );
};

export default observer(OrderDetails);
