import { Box } from "@mui/material";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import Map from "react-map-gl";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

interface LayoutProps {
  children: React.ReactNode;
}

const MapContainer = ({ children }: LayoutProps) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Map
        id={"map1"}
        initialViewState={{
          longitude: 114.10716154371866,
          latitude: 22.37119977182882,
          zoom: 10,
        }}
        mapboxAccessToken="pk.eyJ1Ijoic29jaWZhZG1pbiIsImEiOiJjbDBsemRvMmIweXBxM2Nwd3BuZzRuMDg4In0.lbuRgmmbnvstCcEtB2R5Zw"
        style={{
          width: "100%",
          height: 320,
          borderRadius: 24,
          boxShadow: "0px 0px 10px 1px #E9E9E9",
        }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        maxBounds={[
          [113.67392724609375, 21.922663209325922],
          [114.5919191796875, 22.882501434370063],
        ]}
      >
        {children}
      </Map>
    </Box>
  );
};

export default MapContainer;
