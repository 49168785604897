import { Box, Button, TextField } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PickupBird from "../../assets/PickupBird.png";

const Home = () => {
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState(null);
  const handleChange = (event: any) => {
    setOrderId(event.target.value.trim());
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center", marginY: 2 }}>
        <Box sx={{ marginRight: 1 }}>
          <img alt="PickupBird" src={PickupBird} width={24} />
        </Box>
        <Box sx={{ typography: "h6", fontWeight: "bold" }}>收貨鳥</Box>
      </Box>
      <TextField 
        id="tracking-id-input" 
        variant="outlined" 
        fullWidth 
        placeholder='輸入運單編號' 
        onChange={handleChange}
        value={orderId}
      />
      <Box sx={{ marginY: 2 }}>
        <Button 
          fullWidth 
          color="primary" 
          variant="contained" 
          disabled={!orderId}
          onClick={() => navigate(`order/${orderId}`)}>
          馬上追蹤
        </Button>
      </Box>
    </Box>

  )
}

export default Home;