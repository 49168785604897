import { Box, LinearProgress, Paper, Skeleton } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useETA } from "../../api/useETA";
import { useOrder } from "../../api/useOrder";
import Truck from "../../assets/cargo-truck.svg";

interface ETACardProps {
  orderId?: string;
}

const ETACard = (props: ETACardProps) => {
  const { orderId } = props;
  const { shopEta, deliveryStatus, isShopEtaFetched } = useETA(orderId);
  const { orderSummary } = useOrder({ orderId });

  const deliveryEtaText = useMemo(() => {
    if (!isShopEtaFetched) {
      return "";
    }

    if (deliveryStatus === "arriving") {
      return "即將到達";
    }

    const format = "DD/MM, HH:mm";

    const formatWithStartEnd = (startTime: Dayjs, endTime: Dayjs) => {
      if (startTime.isSame(endTime, "day")) {
        return `${startTime.format(format)} - ${endTime.format("HH:mm")}`;
      } else {
        return `${startTime.format(format)} - ${endTime.format(format)}`;
      }
    };

    if (shopEta?.trip?.endTime && shopEta?.trip.endTripReason !== "CronJob") {
      return dayjs(shopEta.trip.endTime).format(format);
    } else if (shopEta?.eta?.timestamp) {
      const eta = dayjs(shopEta.eta.timestamp);
      // const etaEnd = eta.add(30, "minute");

      return `${eta.diff(dayjs(), "minute")} 分鐘`;
    } else if (orderSummary?.order?.deliveryTime) {
      const startTime = dayjs(orderSummary?.order?.deliveryTime);
      if (orderSummary?.order?.deliveryTimeEnd) {
        const endTime = dayjs(orderSummary?.order?.deliveryTimeEnd);

        return formatWithStartEnd(startTime, endTime);
      } else {
        return startTime.format(format);
      }
    } else {
      return "暫無";
    }
  }, [shopEta, isShopEtaFetched, orderSummary, deliveryStatus]);

  // Not started
  let progress = 0;

  if (shopEta?.trip) {
    if (shopEta.trip.endTime) {
      // Trip Ended
      progress = 100;
    } else {
      // In Progress
      progress = 50;
    }
  }

  return (
    <Paper
      sx={{
        backgroundColor: "white",
        borderRadius: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 2,
        boxShadow: "0px 0px 10px 1px #E9E9E9",
      }}
      elevation={0}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            sx={{
              fontSize: 12,
              textAlign: "left",
              color: "#828282",
              marginBottom: 0.1,
            }}
          >
            {shopEta?.trip?.endTime &&
            shopEta?.trip?.endTripReason !== "CronJob"
              ? "到達時間"
              : "預計到達時間"}
          </Box>
          <Box sx={{ fontSize: 14, fontWeight: "bold", textAlign: "left" }}>
            {isShopEtaFetched ? (
              deliveryEtaText
            ) : (
              <Skeleton variant="text" width={"5vw"} />
            )}
          </Box>
        </Box>
        <img alt={"Truck"} src={Truck} width={32} />
      </Box>
      <Box sx={{ width: "100%", marginY: 0.7 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ fontSize: 12, textAlign: "left" }}>
          {isShopEtaFetched ? (
            {
              not_start_trip: "未開始派送",
              in_progress: "正在派送中",
              suspended: "正在派送中",
              arriving: "正在派送中",
              trip_ended: "已完成派送",
            }[deliveryStatus]
          ) : (
            <Skeleton variant="text" width={"8vw"} />
          )}
        </Box>
        {/**
         * Hide Temporary
         */}
        {/* <Button
          variant="text"
          size="small"
          style={{ fontSize: 12, padding: 0 }}
        >
          SMS 通知
        </Button> */}
      </Box>
    </Paper>
  );
};

export default observer(ETACard);
