import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import { Box, List, ListItem, Skeleton } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useOrder } from "../../api/useOrder";
import "./styles.scss";

interface ContactInfoProps {
  orderId?: string;
}

const ContactInfo = ({ orderId }: ContactInfoProps) => {
  const { orderSummary, isFetched } = useOrder({ orderId });

  const getAddress = () => {
    if (orderSummary?.order === undefined) {
      return "-";
    }

    return `${orderSummary.order?.city} ${orderSummary.order?.address1} ${orderSummary.order?.address2}`;
  };

  return (
    <Box>
      <Box
        sx={{
          textAlign: "left",
          typography: "body1",
          fontWeight: "bold",
          marginTop: 3,
          marginBottom: 2,
        }}
      >
        收貨人資料
      </Box>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          borderRadius: 3,
          boxShadow: "0px 0px 10px 1px #E9E9E9",
        }}
      >
        <ListItem>
          <Box className={"row"}>
            <PersonIcon color={"disabled"} />
            {isFetched ? (
              <div className="item">{orderSummary?.order?.name || "-"}</div>
            ) : (
              <Skeleton variant="text" width={"5vw"} className={"item"} />
            )}
          </Box>
        </ListItem>
        <ListItem>
          <Box className={"row"}>
            <LocationOnIcon color={"disabled"} />
            {isFetched ? (
              <div className="item">{getAddress()}</div>
            ) : (
              <Skeleton variant="text" width={"5vw"} className={"item"} />
            )}
          </Box>
        </ListItem>
        <ListItem>
          <Box className={"row"}>
            <HistoryEduIcon color={"disabled"} />
            {isFetched ? (
              <div className="item">{orderSummary?.order?.note || "-"}</div>
            ) : (
              <Skeleton variant="text" width={"5vw"} className={"item"} />
            )}
          </Box>
        </ListItem>
      </List>
    </Box>
  );
};

export default observer(ContactInfo);
