import polyline from "@mapbox/polyline";
import length from "@turf/length";
import lineSlice from "@turf/line-slice";
import nearestPointOnLine from "@turf/nearest-point-on-line";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { useQuery } from "react-query";
// import { useSearchParams } from "react-router-dom";
import { useRootStore } from "../utils/stores/rootStore";
import { api } from "./axios";

export const useETA = (orderId?: string) => {
  // const [searchParams] = useSearchParams();
  // const orderId = searchParams.get("orderId");
  const { enqueueSnackbar } = useSnackbar();
  const { setStartedTrip } = useRootStore();

  const { data: shopEta, isFetched: isShopEtaFetched } = useQuery(
    ["eta-route", orderId],
    async () => {
      const response = await api.get<StandardResponse<ShopifyOrderEta>>(
        `shopify/eta/${orderId}`
      );

      if (response.status === 200 && "data" in response.data) {
        return response.data.data;
      }
      return null;
    },
    {
      enabled: !!orderId,
      refetchInterval: 5 * 1000,
      retry: 2,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      onError: () => {
        enqueueSnackbar("未有訂單情況", {
          variant: "error",
          preventDuplicate: true,
        });
      },
    }
  );

  const { path, fullPathLength } = useMemo(() => {
    if (shopEta?.shape) {
      // Full Line from the starting point
      const fullLine = polyline.toGeoJSON(shopEta?.shape);
      const fullPathLength = length(
        { type: "Feature", geometry: fullLine, properties: {} },
        { units: "meters" }
      );

      if (
        fullLine.coordinates.length > 0 &&
        shopEta?.vehicleLocation?.coordinates
      ) {
        // Crop according to progress (rationale: no need path that its drove)
        const point = nearestPointOnLine(
          fullLine,
          shopEta.vehicleLocation.coordinates,
          {
            units: "meters",
          }
        );
        const lastPointOfLine =
          fullLine.coordinates[fullLine.coordinates.length - 1];

        const line = lineSlice(
          point.geometry.coordinates,
          lastPointOfLine,
          fullLine
        );

        return {
          path: line.geometry,
          fullPathLength,
        };
      } else {
        return {
          path: fullLine,
          fullPathLength,
        };
      }
    } else {
      return { path: undefined, fullPathLength: 10e10 };
    }
  }, [shopEta]);

  const deliveryStatus = useMemo(() => {
    if (shopEta?.trip === undefined || shopEta?.trip === null) {
      return "not_start_trip";
    } else if (
      shopEta?.trip?.endTime === undefined ||
      shopEta?.trip?.endTime === null
    ) {
      setStartedTrip(true);

      if (
        shopEta?.eta?.diff !== undefined &&
        shopEta?.eta?.diff !== null &&
        shopEta?.eta?.diff <= 3
      ) {
        return "arriving";
      } else {
        return "in_progress";
      }
    } else if (shopEta?.trip?.endTripReason === "CronJob") {
      setStartedTrip(true);
      return "suspended";
    } else {
      setStartedTrip(true);
      return "trip_ended";
    }
  }, [shopEta, setStartedTrip]);

  return {
    shopEta,
    route: shopEta?.route,
    path,
    fullPathLength,
    deliveryStatus,
    isShopEtaFetched,
  };
};

// https://jaco-test.myshopify.com/admin/oauth authorize?client_id=311cae449851cd8981ff2a45d82a9c65&scope=unauthenticated_read_orders&redirect_uri=https://www.google.com/&state=nonce1
