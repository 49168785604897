import axios from "axios";

export const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:7074/api/"
      : "https://360-api-dev.socif.co/api/",
  timeout: 60 * 1000,
  validateStatus: (status) => status <= 500,
});
